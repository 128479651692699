import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EditProfileDailogComponent } from 'src/shared/components/dialog/admin-dialogs/edit-profile-dialog/edit-profile-dialog.component';
import { UserManagementService } from '../../../user-management/services/user-management.service';
import { ResetPasswordDialogComponent } from 'src/shared/components/dialog/admin-dialogs/reset-password-dialog/reset-password-dialog.component';

@Component({
  selector: 'app-setting',
  templateUrl: './setting.component.html',
})
export class SettingComponent implements OnInit {
  userName: string = '';
  userEmail: string = '';
  portalType: string = '';
  userInitials: string = '';
  element: any;
  loading: boolean = false;
  userID: string = '';

  constructor(
    public dialog: MatDialog,
    private userService: UserManagementService
  ) {}

  ngOnInit(): void {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    this.userID = user?.userID;
    this.fetchUserDetails();
  }

  getInitials(userName: string): string {
    if (!userName) return '';
    const nameParts = userName.split(' ');
    const initials = nameParts.map((part) => part.charAt(0)).join('');
    return initials.toUpperCase();
  }

  fetchUserDetails(): void {
    this.loading = true;
    this.userService.getUserById(this.userID).subscribe({
      next: (res) => {
        const userData = res.data;
        this.element = userData;
        this.userName = userData.name;
        this.userEmail = userData.email;
        this.portalType = userData.portolType;
        this.userInitials = this.getInitials(this.userName);
        this.loading = false;
      },
      error: () => {
        this.loading = false;
      },
    });
  }

  openEditDialog(element: any): void {
    const dialogRef = this.dialog.open(EditProfileDailogComponent, {
      height: 'auto',
      width: '467px',
      data: { element: element },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.success) {
        this.fetchUserDetails();
      }
    });
  }

  openResetPasswordDialog(element: any): void {
    const dialogRef = this.dialog.open(ResetPasswordDialogComponent, {
      height: 'auto',
      width: '467px',
      data: { isEditMode: true, userId: element.userID },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.success) {
        this.fetchUserDetails();
      }
    });
  }
}
