<div class="main-content body-bg p-6 setting-wrapper">
  <div class="d-flex justify-between align-center mb-6">
    <div>
      <h4 class="mat-headline-5 strong text" style="margin-bottom: 0">
        Settings
      </h4>
      <p class="medium mat-body-1 text textXS" style="margin-top: 0">
        Profile setting
      </p>
    </div>
  </div>
  <div class="d-flex justify-center">
    <div class="account-detail-tab settings w-full">
      <div class="p-6">
        <mat-tab-group>
          <mat-tab>
            <ng-template mat-tab-label>
              <span class="material-symbols-outlined mr-2">
                manage_accounts
              </span>
              Account
            </ng-template>
            <app-loader *ngIf="loading"></app-loader>
            <div>
              <div
                style="
                  width: 98px;
                  height: 98px;
                  border-radius: 50%;
                  background-color: #005F8E;
                  color: white;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  font-size: 50px;
                  font-weight: bold;
                  margin-top: 30px;
                  margin-bottom: 30px;
                "
              >
                {{ userInitials }}
              </div>
              <div
                class="d-flex align-center justify-between tab-border-bottom pb-6 mb-6"
              >
                <div>
                  <h2 class="mat-headline-5" style="margin-bottom: 0">
                    {{ userName }}
                  </h2>
                  <span class="text textS">
                    {{ portalType === "admin" ? "Admin" : portalType }}
                  </span>
                </div>
                <sft-button
                  [style.cursor]="'not-allowed'"
                  [label]="'Edit'"
                  [color]="'primary'"
                  [variant]="'flat'"
                  [icon]="'home'"
                  [disableRipple]="false"
                  [size]="'medium'"
                  (buttonClick)="openEditDialog(element)"
                ></sft-button>
              </div>
              <div class="d-flex mb-6">
                <span class="material-symbols-outlined mr-2">
                  alternate_email
                </span>
                <div>
                  <span class="text textS d-block">Email</span>
                  <span class="textS grey-text d-block">{{ userEmail }}</span>
                </div>
              </div>
              <div class="d-flex mb-6 tab-border-bottom pb-6">
                <span class="material-symbols-outlined mr-2"> Contacts </span>
                <div>
                  <span class="text textS d-block">Contact </span>
                  <span class="textS grey-text d-block">--</span>
                </div>
              </div>
              <div class="d-flex pb-6">
                <span class="material-symbols-outlined mr-2"> encrypted </span>
                <div>
                  <span class="text textS d-block">Password</span>
                  <div class="reset-button-wrapper">
                    <sft-button
                      [style.cursor]="'not-allowed'"
                      [label]="'Reset'"
                      [color]="'primary'"
                      [variant]="'default'"
                      [icon]="'home'"
                      [disableRipple]="false"
                      [size]="'small'"
                      (buttonClick)="openResetPasswordDialog(element)"
                    ></sft-button>
                  </div>
                </div>
              </div>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>
</div>
